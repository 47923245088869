import { addDays, format } from "date-fns";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import STEP from "../constants/STEP";
import useFetchTaxes from "../hooks/useFetchTaxes";
import useMail from "../hooks/useMail";
import { createBudget } from "../services/budgetService";
import { sendEmail } from "../services/emailService";
import { getUserName, getUserId } from "../services/auth";
import { createDatesArr } from "../utils/utils";
import { dateToString, stringToDate } from "../mapping/taxMapping";
import generateUniqueHash from "../utils/generateUniqueHash";
import { ContentPasteOutlined } from "@material-ui/icons";
const _ = require("lodash");
//const defaultTax = 7.25 / 100;
const defaultDiscount = 5;

const rulesCase = {
    verify: (dates, rule) => {
      const ruleDates = Object.keys(rule).slice(1);
      if (dates.length < rule[ruleDates[0]]?.number_min_nights) return false;
      return ruleDates.filter(ruleDate =>
          dates.includes(ruleDate)
      )
    },
    apply: (dates, rule, prices) => {
      const getDate = (rule) =>
          rule[dates[0]]?.flag_nights_free === "PRI" ? dates[0] : dates[dates.length - 1];

      return rule[dates[0]]?.flag_nights_free === 'PRI' ||  rule[dates[0]]?.flag_nights_free === 'ULT'?
          {
            value:
              prices[getDate(rule)]?.daily_price * 1.0725 //1.0725 = hotel tax

          } : {
            percentual:
              _.sum(dates.map((date) => {
                if(rule[date]?.perc_discount) {
                  return (prices[date]?.daily_price * 1.0725) * (rule[date]?.perc_discount / 100) //1.0725 = hotel tax
                } else {
                  return 0
                }              
              })),
          }
    },

  "5x4 1A NOITE FREE": {
    verify: (dates, rule) => {
      const beforeDayBeginPeriod = dateToString(
        addDays(stringToDate(dates[0]), -1)
      );
      const afterDayEndPeriod = dateToString(
        addDays(stringToDate(dates[dates.length - 1]), 1)
      );
      if (rule[beforeDayBeginPeriod] || rule[afterDayEndPeriod]) return false;

      return dates.every((date) => rule[date]?.number_nights_free);
    },
    apply: (dates, rule, prices) => {
      const getDate = (rule) =>
        rule.flag_nights_free === "PRI" ? dates[0] : dates[dates.length - 1];

      const daily_discount = prices[getDate(rule)]?.daily_price || 0;

      return {
        discount:
          (100 * daily_discount) /
          _.sum(dates.map((date) => prices[date]?.daily_price || 0)),
      };
    },
  },
  "4RN -5%": {
    verify: (dates, rule) => {
      const ruleDates = Object.keys(rule).slice(1);
      if (dates.length < rule[ruleDates[0]]?.number_min_nights) return false;
      return ruleDates.filter(ruleDate =>
          dates.includes(ruleDate)
      )
    },
    apply: (dates, rule) => ({
      discount:
        _.sum(dates.map((date) => rule[date]?.perc_discount || 0)) /
        dates.length,
    }),
  },
  "4RN -10%": {
    verify: (dates, rule) => {
      const ruleDates = Object.keys(rule).slice(1);
      if (dates.length < rule[ruleDates[0]]?.number_min_nights) return false;
      return ruleDates.filter(ruleDate =>
          dates.includes(ruleDate)
      )
    },
    apply: (dates, rule) => ({
      discount:
        _.sum(dates.map((date) => rule[date]?.perc_discount || 0)) /
        dates.length,
    }),
  },
  "5RN -10%": {
    verify: (dates, rule) => {
      const ruleDates = Object.keys(rule).slice(1);
      if (dates.length < rule[ruleDates[0]]?.number_min_nights) return false;
      return ruleDates.filter(ruleDate =>
          dates.includes(ruleDate)
      )
    },
    apply: (dates, rule) => ({
      discount:
        _.sum(dates.map((date) => rule[date]?.perc_discount || 0)) /
        dates.length,
    }),
  },
  "6RN -10%": {
    verify: (dates, rule) => {
      const ruleDates = Object.keys(rule).slice(1);
      if (dates.length < rule[ruleDates[0]]?.number_min_nights) return false;
      return ruleDates.filter(ruleDate =>
          dates.includes(ruleDate)
      )
    },
    apply: (dates, rule) => ({
      discount:
        _.sum(dates.map((date) => rule[date]?.perc_discount || 0)) /
        dates.length,
    }),
  },
  "7RN -10%": {
    verify: (dates, rule) => {
      const ruleDates = Object.keys(rule).slice(1);
      if (dates.length < rule[ruleDates[0]]?.number_min_nights) return false;
      return ruleDates.filter(ruleDate =>
          dates.includes(ruleDate)
      )
    },
    apply: (dates, rule) => ({
      discount:
        _.sum(dates.map((date) => rule[date]?.perc_discount || 0)) /
        dates.length,
    }),
  },
};

const rulesCaseDaily = {
  apply: (dates, rule, prices) => {
    const getDate = (rule) =>
        rule[dates[0]]?.flag_nights_free === "PRI" ? dates[0] : dates[dates.length - 1];

    return rule[dates[0]]?.flag_nights_free === 'PRI' ||  rule[dates[0]]?.flag_nights_free === 'ULT'?
        {
          value:
            prices[getDate(rule)]?.daily_price  //1.0725 = hotel tax

        } : {
          percentual:
            _.sum(dates.map((date) => {
              if(rule[date]?.perc_discount) {
                return (prices[date]?.daily_price ) * (rule[date]?.perc_discount / 100) //1.0725 = hotel tax
              } else {
                return 0
              }              
            })),
        }
  },
};

//Valores iniciais do contexto global
export const BudgetFormContextDefaultValues = {
  params: {
    check_in: new Date(),
    check_out: new Date(),
    adults_quantity: 2,
    children_quantity: 1,
    babies_quantity: 0,
  },
  data: { dates: [], tax: [], prices: [], rules: [], prices_daily: [] },
  checkedRules: {},
  selectedDates: [],
  fetchData: () => {},
  setRange: () => {},
  setSelectedDates: () => {},
  setCheckedRules: () => {},
};

//Instância do contexto, recebendo os valores iniciais
export const BudgetFormContext = createContext(BudgetFormContextDefaultValues);

//Provedor que é resposável pela manipulação e controle do estado
const BudgetFormContextProvider = ({ children, setIsForm }) => {
  const [selectedPrices, setSelectedPrices] = useState([]);
  const [selectedPricesDaily, setSelectedPricesDaily] = useState([]);
  const [extras, setExtras] = useState(0);
  const [loading, setLoading] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [budgets, setBudgets] = useState([]);
  const [params, changeParams] = useState(
    BudgetFormContextDefaultValues.params
  );
  const [checkedRules, setCheckedRules] = useState({});
  const [selectedDates, setSelectedDates] = useState([]);

  const [step, setStep] = useState(STEP.BUDGET);

  const { data, fetchData } = useFetchTaxes();
  const { mail, changeMail } = useMail();
  const [discountInput, setDiscountInput] = useState(0);
  const [percDiscount, setPercDiscount] = useState(0);
  const [hashBudgets, setHashBudgets] = useState(generateUniqueHash());

  useEffect(() => {
    if (budgets.length > 0) {
      setHashBudgets(generateUniqueHash());
    }

  }, [budgets]);

  // useEffect(() => {
  //   console.log(selectedDates)

  // }, [selectedDates]);


  const stop = 1,
        fecho = 2,
        fecho_in = 3,
        fecho_out = 4;

  // useEffect(() => {
  //   setSelectedDates(_.take(_.drop(data.dates, 15), 15));
  // }, [_, data.dates]);


  const hotelTax = 1.0725;
  useEffect(() => {
    if (selectedDates.length === 0) return;

    const ruledDiscount = percDiscount > 0 ? percDiscount / 100 : defaultDiscount / 100

    const groupedPrices = [];
    const dailyPrices = [];

    let groupedDiscounts = {};

    data.prices.forEach((item) => {
      let currentItem = _.cloneDeep(item); 

      const dates_diff = selectedDates.length;
      
      groupedDiscounts[item.acommodation_description] = { discount: 0 };
    
      if (
        selectedDates.some((date) => {
          const dateParts = selectedDates[selectedDates.length - 1].split("/");
          const endDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
          
          return (
            (item[date]?.restriction_type === stop) ||
            item[selectedDates[0]]?.restriction_type === fecho_in ||
            item[selectedDates[0]]?.restriction_type === fecho ||
            item[format(addDays(endDate, 1), 'dd/MM/yyyy')]?.restriction_type === fecho_out ||
            item[format(addDays(endDate, 1), 'dd/MM/yyyy')]?.restriction_type === fecho
          );
        })
      ) return;
    
      const currentPrice = {};
    
      let min_room_nights = 0;
      selectedDates.forEach((date)=>{
          const min_room_night = item[date]?.min_room_nights;

          if(min_room_night > min_room_nights){
            min_room_nights = min_room_night
          }

      })
      selectedDates.forEach((date) => {
        const dailyPrice = item[date]?.daily_price || 0;
        let dailyPriceForPush;
        const totalPrice = dailyPrice + (currentPrice[item.acommodation_description]?.total_daily_without_tax || 0);
    
        const priceDate = item[date]?.accomodation_date;
        const children_free = item[date]?.children_free;
        const min_room_night_children_free = item[date]?.min_room_night_children_free;
        
    
        // Create a copy of the current accommodation's price data
        currentPrice[item.acommodation_description] = {
          total_daily_without_tax: totalPrice,
          total_cost_accomodation: totalPrice,
          total_daily_with_tax: totalPrice, //* hotelTax,
          total_daily_with_tax_discount: totalPrice, // * hotelTax,
          acommodation_date: priceDate,
          children_free,
          min_room_night_children_free,
          min_room_nights,
          tax_id: item[date]?.tax_id,
        };
    
        const rulesDaily = data.rules
        .filter((rule) => (checkedRules[rule.rule_description]?.checked || false) && rulesCase.verify(selectedDates, rule))
        .sort((a, b) => {
          // Get the order values for both rules
          const orderA = checkedRules[a.rule_description]?.order ?? Number.MAX_SAFE_INTEGER;
          const orderB = checkedRules[b.rule_description]?.order ?? Number.MAX_SAFE_INTEGER;
          
          return orderA - orderB;
        })
        .map((rule) => {
          
          const itemToUse = rule.flag_cumulative === 'S' ? currentItem : item;
          const priceAdjustment = rulesCaseDaily.apply([date], rule, itemToUse);
          const priceAdjustmentValue = priceAdjustment.value ? priceAdjustment.value : priceAdjustment.percentual;
      
          if (currentItem[date]?.daily_price !== undefined) {
            currentItem[date].daily_price -= priceAdjustmentValue;
          } else {
            console.log("currentItem.date.daily_price is undefined, no adjustment made.");
          }
      
          return priceAdjustment;
        });
    
        const rulePercDiscount = _.sumBy(rulesDaily, "percentual") || 0;
        const ruleValueDiscount = _.sumBy(rulesDaily, "value") || 0;

        const discountValue = (rulePercDiscount > 0 ? rulePercDiscount : ruleValueDiscount)

        groupedDiscounts[item.acommodation_description].discount += discountValue;
    
        dailyPriceForPush = dailyPrice - discountValue;
        dailyPrices.push({
          acommodation_description: item.acommodation_description,
          date,
          daily_price: dailyPriceForPush,
          daily_price_with_tax: dailyPriceForPush * hotelTax,
          daily_base_price: dailyPrice,
          discount: 0,
          children_free,
          min_room_night_children_free,
          tax_id: item[date]?.tax_id,
          acommodation_id: item[date]?.uh_type_id,
        });
      });
   
      if (dates_diff >= currentPrice[item.acommodation_description].min_room_nights) {
        
        groupedPrices.push({
          ...currentPrice[item.acommodation_description],
          acommodation_description: item.acommodation_description,
        });
      } 
      
    });

     for (let i = 0; i < groupedPrices.length; i++) {
       const item = groupedPrices[i];
    //   const rules = data.rules
    //     .filter(
    //       (rule) =>

    //         (checkedRules[rule.rule_description]?.checked || false) &&

    //         //rulesCase[rule.rule_description].verify(selectedDates, rule)
    //         rulesCase.verify(selectedDates, rule)
    //     )
    //     .map((rule) =>
    //       //rulesCase[rule.rule_description].apply(
    //       rulesCase.apply(
    //         selectedDates,
    //         rule,
    //         data.prices.find(
    //           (price) =>
    //             price.acommodation_description === item.acommodation_description
    //         )
    //       )
    //     );

    //   const rulePercDiscount = _.sumBy(rules, "percentual") || 0 ;
    //   const ruleValueDiscount = _.sumBy(rules, "value") || 0 ;

      // const price =
      //   item.total_daily_with_tax -
      //   (rulePercDiscount > 0
      //     ? rulePercDiscount
      //     : ruleValueDiscount) ;

      // const priceWithDiscount =
      //   item.total_daily_with_tax_discount -
      //   (rulePercDiscount > 0
      //     ? rulePercDiscount
      //     : ruleValueDiscount) ;

      const price =
        item.total_daily_with_tax -
        groupedDiscounts[item.acommodation_description].discount;

      const priceWithDiscount =
          item.total_daily_with_tax_discount -
          groupedDiscounts[item.acommodation_description].discount;

      const discountValue = priceWithDiscount * ruledDiscount ;

      item.total_daily_with_tax_discount = ((priceWithDiscount * hotelTax) + extras) - (discountInput > 0 ? discountInput : (discountValue * hotelTax)) ;
      
      item.total_cost_accomodation = ( (price * hotelTax ) + extras) ;

    
    }

    setSelectedPrices(groupedPrices);

    setSelectedPricesDaily(dailyPrices);
    
  }, [params, data, checkedRules, selectedDates, extras, discount, discountInput, percDiscount]);

  const handleCancel = useCallback(() => setIsForm(false), [setIsForm]);
  const handleSubmit = useCallback(async () => {
    const details = budgets.map((budget) => ({
      check_in: budget.check_in,
      check_out: budget.check_out,
      adults_quantity: budget.adults_quantity,
      children_quantity: budget.children_quantity,
      babies_quantity: budget.babies_quantity,
      prices: budget.prices.map((price) => ({
        uh_type: price.acommodation_description,
        date: price.acommodation_date,
        id_tarifa: price.tax_id || 0,
        price: price.total_cost_accomodation,
        discount: 0,
      })),
      prices_daily: budget.prices_daily,
    }));

    const budgetObject = {
      mail: mail.body,
      clerk: getUserName(),
      client: {
        name: mail.clientName,
        email: mail.clientEmail,
        phone: mail.clientPhone,
      },
      hash: hashBudgets,
      details,
    };

    try {

      if (!mail.clientNameError && !mail.clientEmailError) {
        const response = await createBudget(budgetObject);

        console.log("CREATE BUDGET",response)
       
        if (response.success) {
          await sendEmail({
            operatorId: getUserId(),
            to: mail.clientEmail,
            cc: mail.copyEmail,
            subject: "Segue seu orçamento",
            mailBody: mail.body,
            attachments: mail.attachments,
          });
          window.location.href = "/app/orcamentos";
        } else {
          alert('Erro ao criar o Orçamento tente novamente por favor')
        }



      } else {
        alert('Erros no formulário preencha corretamente por favor');
      }

    } catch (e) {}
  }, [mail, budgets]);

  const addToBudget = useCallback(() => {

    const newBudget = {
      ...params,
      check_in: stringToDate(selectedDates[0]),
      check_out: addDays(stringToDate(selectedDates[selectedDates.length - 1]), 1),
      prices: selectedPrices,
      prices_daily: selectedPricesDaily,
      children_free: Math.max.apply(
        Math,
        selectedPrices.map((price) => price.children_free)
      ),
      min_room_night_children_free: selectedDates.length >= Math.max.apply(
        Math,
        selectedPrices.map((price) => price.min_room_night_children_free)
      ) ? true : false,
      extras: extras || 0,
    };



    setBudgets([...budgets, newBudget]);
    setSelectedPrices([]);
  }, [params, budgets, selectedPrices]);

  const removeFromBudget = useCallback(
    (indexOf) => {
      setBudgets(budgets.filter((_, i) => i !== indexOf));
    },
    [budgets]
  );

  const handleSearch = useCallback(async () => {
    const check_in = format(params.check_in, "dd/MM/yyyy");
    const check_out = format(params.check_out, "dd/MM/yyyy");
    const check_in15 = format(addDays(params.check_in, -15), "dd/MM/yyyy");
    const check_out15 = format(addDays(params.check_out, 15), "dd/MM/yyyy");
    setSelectedDates(createDatesArr(params.check_in, params.check_out));
    setLoading(true);
    await fetchData({
      ...params,
      check_in,
      check_out,
      check_in15,
      check_out15,
    });

    setLoading(false);

    // setRange([data.dates.indexOf(check_in), data.dates.indexOf(check_out)]);
  }, [data.dates, params, fetchData]);

  const handleNextStep = useCallback(() => {
    const newStep = Object.values(STEP)[step + 1];
    setStep(newStep);
  }, [step]);

  const handleBackStep = useCallback(() => {
    const newStep = Object.values(STEP)[step - 1];
    setStep(newStep);
  }, [step]);

  const value = useMemo(
    () => ({
      data,
      step,
      loading,
      selectedPrices,
      selectedPricesDaily,
      mail,
      budgets,
      params,
      selectedDates,
      checkedRules,
      changeParams,
      changeMail,
      handleCancel,
      handleSubmit,
      handleSearch,
      handleNextStep,
      handleBackStep,
      addToBudget,
      removeFromBudget,
      setExtras,
      setDiscount,
      setSelectedDates,
      setCheckedRules,
      defaultDiscount,
      extras,
      setPercDiscount,
      setDiscountInput,
      hashBudgets // Adicionando hashBudgets ao contexto
    }),
    [
      data,
      step,
      loading,
      selectedPrices,
      selectedPricesDaily,
      selectedDates,
      checkedRules,
      mail,
      budgets,
      params,
      changeMail,
      handleCancel,
      handleSubmit,
      handleSearch,
      handleNextStep,
      handleBackStep,
      removeFromBudget,
      addToBudget,
      defaultDiscount,
      extras,
      hashBudgets // Incluindo hashBudgets nas dependências
    ]
  );

  return (
    <BudgetFormContext.Provider value={value}>
      {children}
    </BudgetFormContext.Provider>
  );
};

//Hook que será utilizando nos componentes para acesso ao contexto
export const useBudgetFormContext = () => useContext(BudgetFormContext);

export default BudgetFormContextProvider;